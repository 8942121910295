.AppContainer {
    width: 100%;
}

.App-header {
    background-color: #8bb9f6;
    min-height: 8vh;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .App-header h1 {
    font-size: calc(10px + 2vmin);
    color: white;
    flex-grow: 1;
    margin: 0;
    text-align: center;
  }

  .App-header .CloseButton {
    position: absolute;
    right: 5%; /* Align icon to the right */
    top: 50%;
    transform: translateY(-50%); /* Center icon vertically */
    color: white;
  }

  .FAQ {
    max-width: 80%;
    margin: 0 auto;
  }